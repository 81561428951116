import React, { useEffect, useRef } from 'react';
import useWindowSize from '@/hooks/useWindowSize';
import dynamic from 'next/dynamic';
import { MenuToggle } from './Shared/MenuToggle';
import { motion, useCycle } from 'framer-motion';
import { variants_menuItemContainer } from './Shared/variants';
import cx from 'classnames';
const SharedStylesJsx = dynamic(() => import('./Shared/Shared.styles'));
const Nav = dynamic(() => import('./Shared/Nav'));
const StarLogo = dynamic(() => import('../../svgs/Logos/StarLogo'));
const TopMobileNav = dynamic(() => import('./Mobile/TopMobileNav'));
const CustomRightNav = dynamic(() => import('./Desktop/CustomRightNav'));

const MobileMenu = ({ toggleOpen }) => {
    return (
        <div className="overflow-scroll">
            <TopMobileNav toggleOpen={toggleOpen} />
            <Nav type="mobile" location="MEGALEFT" toggleOpen={toggleOpen} hoverBgColor="bg-lightBlue" hoverTextColor="text-white" />
        </div>
    );
};

const DesktopMenu = ({ toggleOpen, isOpen }) => {
    if (!isOpen) return null;
    return (
        <div className="relative mx-auto grid max-w-[1000px] grid-cols-2 justify-center gap-3 py-20">
            <div className="flex justify-center pl-10">
                <Nav location="MEGALEFT" toggleOpen={toggleOpen} hoverBgColor="bg-lightBlue" hoverTextColor="text-white" />
            </div>
            <div className="right-nav-container mx-auto max-w-[370px] pr-12 text-[#222] dark:text-white">
                <CustomRightNav toggleOpen={toggleOpen} />
            </div>
        </div>
    );
};

export default function Menu() {
    const size = useWindowSize();
    const buttonRef = useRef(null);
    const [isOpen, toggleOpen] = useCycle(false, true);

    useEffect(() => {
        const body = document.querySelector('body').classList;
        isOpen ? body.add('overflow-hidden') : body.remove('overflow-hidden');
    }, [isOpen]);

    return (
        <div className={isOpen ? 'absolute h-full w-[21px]' : ''}>
            <motion.div animate={isOpen ? 'open' : 'closed'} initial="closed">
                <MenuToggle buttonRef={buttonRef} isOpen={isOpen} toggle={() => toggleOpen()} />
                <div
                    className={cx([
                        'pointer-events-none fixed left-0 top-0 z-[12] mx-auto flex h-screen w-full flex-col items-center justify-center bg-white dark:bg-darkModeBg',
                        isOpen ? 'visible' : 'hidden'
                    ])}>
                    <motion.div
                        variants={variants_menuItemContainer}
                        className={`menuItemsContainer container pointer-events-auto relative flex flex-col justify-center overflow-scroll md:h-screen md:overflow-visible`}>
                        <div className="flex justify-center md:absolute md:left-12 md:top-0 md:z-0 md:ml-10 md:h-[50px] md:w-[50px] 2xl:left-0">
                            <StarLogo toggleOpen={toggleOpen} />
                        </div>
                        <div className="menu-holder overflow-scroll md:overflow-visible">
                            {size.width <= 767 ? <MobileMenu toggleOpen={toggleOpen} isOpen={isOpen} /> : <DesktopMenu toggleOpen={toggleOpen} isOpen={isOpen} />}
                        </div>
                    </motion.div>
                </div>
                {SharedStylesJsx.styles}
            </motion.div>
        </div>
    );
}
