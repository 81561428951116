import { motion } from 'framer-motion';
import cx from 'classnames';
const Path = props => <motion.path strokeWidth="3" strokeLinecap="round" {...props} />;

export const MenuToggle = ({ toggle, buttonRef, isOpen }) => {
    return (
        <button title="Menu" onClick={toggle} id="navToggle" ref={buttonRef} className="relative z-50 flex w-6 items-center" style={{ height: '17px' }}>
            <svg width="23" height="23" viewBox="0 0 23 23" className={cx(['stroke-current', isOpen ? 'fixed top-8 z-50 text-gray' : 'mt-1 text-black dark:text-white'])}>
                <Path
                    variants={{
                        closed: { d: 'M 2 2.5 L 20 2.5' },
                        open: { d: 'M 3 16.5 L 17 2.5' }
                    }}
                />
                <Path
                    d="M 2 9.423 L 20 9.423"
                    variants={{
                        closed: { opacity: 1 },
                        open: { opacity: 0 }
                    }}
                    transition={{ duration: 0.1 }}
                />
                <Path
                    variants={{
                        closed: { d: 'M 2 16.346 L 20 16.346' },
                        open: { d: 'M 3 2.5 L 17 16.346' }
                    }}
                />
            </svg>
        </button>
    );
};
